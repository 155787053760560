<template>
	<el-dialog :title="'签收/封闭'" :close-on-click-modal="false" :visible.sync="visible" width="940px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="85px">
			<el-row>
				<el-col :span="24">
					<el-form-item label="文件编号">
						{{ dataForm.fileNo }}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-card body-style="padding-bottom: 0;">
						<div>签收/意见</div>
						<vue-ueditor-wrap v-model="dataForm.receiveComments" :config="editorConfig" />
						<el-col :span="12" class="con-le" style="margin-left: -15px;">
							<el-form-item label="签收人">
								<el-input v-model="dataForm.receiveMan" placeholder=""></el-input>
							</el-form-item></el-col>
						<el-col :span="12" class="con-le">
							<el-form-item label="日期">
								<el-date-picker style="width: 148px;" v-model="dataForm.receiveDate" type="date"
									value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-card>
					<el-card body-style="padding-bottom: 0;" style="margin-top: 10px;">
						<div>处理结果</div>
						<vue-ueditor-wrap v-model="dataForm.handleOptions" :config="editorConfig" />
						<el-col :span="12" class="con-le" style="margin-left: -15px;">
							<el-form-item label="监理工程师">
								<el-input v-model="dataForm.closeMan" placeholder=""></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12" class="con-le">
							<el-form-item label="日期">
								<el-date-picker style="width: 148px;" v-model="dataForm.closeTime" type="date"
									value-format="yyyy-MM-dd" placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card body-style="padding-bottom: 0;padding-right:0" style="height: 100%;margin-top: 10px;">
						<el-form-item label="封闭文件">
							<el-upload ref="uploadScanFile" class="upload-demo" action="action" drag
								:http-request="uploadScanFile" multiple :show-file-list=false :auto-upload="true">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							</el-upload>
						</el-form-item>
						<el-table v-if="fileList && fileList.length > 0" :data="fileList" style="margin-left: -0px;" :cell-style="{ padding: '0px' }">
							<el-table-column type="index" label="序号" width="">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名" align="center" width="190px">
								<template slot-scope="scope">
									<el-input v-model="fileList[scope.$index].fileName" placeholder="请填写"
										class="fileinput"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="" align="center">
								<template slot-scope="scope">
									<el-button size="mini" type="text" @click="viewFile(scope.$index)">查看</el-button>
									<el-button size="mini" type="text" @click="delFile(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-card>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "superdocument-audit",
	data() {
		return {
			editorConfig: {
				UEDITOR_HOME_URL: "/UEditor/"
			},
			fileList: [],
			action: '',
			visible: false,
			inline: false,
			documentTypeList: [],
			type: '',
			dataForm: {
				fileId: '',
				closeFlg: '',
				closeMan: '',
				closeTime: '',
				fileList: [],
				subjectName: '',
				receiveMan: '',
				receiveDate: '',
				receiveComments: ''
			},
			dataRule: {
				closeMan: [
					{ required: true, message: "请输入封闭人", trigger: "blur" }
				],
				closeTime: [
					{ required: true, message: "请选择封闭时间", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.dataForm = {
				fileId: '',
				closeFlg: '',
				closeMan: '',
				closeTime: '',
				fileList: [],
				receiveMan: '',
				receiveDate: '',
				receiveComments: ''
			};
			this.fileList = []
			this.dataForm.fileId = id ? id : 0;
			this.$nextTick(() => {
				this.visible = true;
				if (this.dataForm.fileId) {
					this.getDetail();
				}
				// this.$refs['dataForm'].resetFields();
			});
		},
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		beforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		handleRemove(file, fileList) {
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					let item = {
						fileId: this.dataForm.fileId,
						name: res.body.fileName,
						url: res.body.fileName,
						fileName: res.body.fileName,
						filePathUrl: res.body.fileUri,
						filePath: res.body.fileSavePath
					}
					this.fileList.push(item)
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + `/business/supervisioncontactinfo/info/${this.dataForm.fileId}`,
				method: "get",
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = {
						fileId: data.body.fileId,
						fileNo: data.body.fileNo,
						fileTitle: data.body.fileTitle,
						fileContent: data.body.fileContent,
						handleOptions: data.body.handleOptions,
						subjectName: data.body.subjectName,
						receiveMan: data.body.receiveMan,
						receiveDate: data.body.receiveDate,
						closeFlg: '1',
						closeMan: data.body.closeMan,
						closeTime: data.body.closeTime,
						receiveComments: data.body.receiveComments,
						fileList: data.body.fileList
					};
					this.fileList = this.dataForm.fileList
				}
			});
		},
		getDocumentType(value) {
			let name = "监理联系单";
			if (value === '02') {
				name = "监理通知单"
			}
			return name;
		},
		viewFile(index) {
			window.open(this.fileList[index].filePathUrl);
		},
		delFile(index) {
			this.fileList.splice(index, 1)
		},
		//表单提交
		dataFormSubmit() {
			this.dataForm.fileList = this.fileList
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/supervisioncontactinfo/closedUpdateButtonContactInfoProcessing";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						}
					});
				}
			});
		}
	}
}
</script>
<style scoped>
.selItemInput.descAreaLg {
	width: 640px !important;
}

.con-le {
	margin-top: 10px;

}

.upload-demo /deep/ .el-upload-dragger {
	width: 260px;
	height: 80px;
	margin-top: 10px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin: 0 0;
	line-height: 40px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 20px;
}
</style>
